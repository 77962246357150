import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalFooter,
  Button,
  Flex,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  Input,
  Text,
  Textarea,
  Stack,
  NumberInputField,
  NumberInput,
  IconButton,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { addServiceRequest } from "redux/actions";
import { connect, useDispatch, useSelector } from "react-redux";
import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import { updateServiceRequest } from "redux/actions";
import toast from "react-hot-toast";
import { deleteSubserviceRequest } from "redux/actions";

const AddServiceForm = ({ isOpen, onClose, serviceData }) => {
  let [name, setName] = useState("");
  let [price, setPrice] = useState(0);
  let [durationInDay, setDurationInDay] = useState("");
  let [description, setDescription] = useState("");
  const [sousType, setSousType] = useState("non");
  const [subservicesList, setSubservicesList] = useState([]);
  const [showSubserviceForm, setShowSubserviceForm] = useState(false);

  const loading = useSelector((state) => state.DashboardReducer.loading_action);
  const closeModal = useSelector((state) => state.DashboardReducer.closeModal);

  useEffect(() => {
    if (closeModal) {
      onClose();
    }
  }, [closeModal]);

  useEffect(() => {
    if (serviceData) {
      setName(serviceData.name || "");
      setDescription(serviceData.description || "");
      setPrice(serviceData.price || 0);
      setDurationInDay(serviceData.durationInDay || "");

      if (serviceData.isFree) {
        setSousType("oui");
      } else {
        setSousType("non");
      }
    }
  }, [serviceData]);

  const dispatch = useDispatch();

  const handleRadioChange = (value) => {
    setSousType(value);
    setShowSubserviceForm(value === "oui");
  };

  let handleNameChange = (e) => {
    let inputValue = e.target.value;
    setName(inputValue);
  };

  const handlePriceChange = (valueString, valueNumber) => {
    setPrice(valueString);
  };
  const handleDurationInDayChange = (valueString, valueNumber) => {
    setDurationInDay(valueString);
  };

  let handleDescriptionChange = (e) => {
    let inputValue = e.target.value;
    setDescription(inputValue);
  };


  const handleRemoveSubservice = (index) => {
    const updatedSubservicesList = [...subservicesList];
    updatedSubservicesList.splice(index, 1);
    setSubservicesList(updatedSubservicesList);
  };

  const handleRemoveOnlineSubservice = (index) => {
    const updatedSubservicesList = [...subservicesList];
    updatedSubservicesList.splice(index, 1);
    setSubservicesList(updatedSubservicesList);
    dispatch(
      deleteSubserviceRequest({
        id: serviceData?._id,
        optionId: serviceData?.subservices[index]._id,
      })
    );
  };

  const handleRemove = (index) => {
    if (serviceData) {
      handleRemoveOnlineSubservice(index);
    } else {
      handleRemoveSubservice(index);
    }
  };

  const handleAddSubservice = () => {
    setSubservicesList([...subservicesList, {}]);
  };

  const handleAddService = (e) => {
    e.preventDefault();

    if (!sousType) {
      toast.error("Verifiez que vous avez rempli tout les champs obligatoires");
      return;
    }

    if (!name || !description || !price || !durationInDay) {
      toast.error("Verifiez que vous avez rempli tout les champs obligatoires");
      return;
    }

    let serviceData = {
      name: name,
      type: "service",
    };

    serviceData.description = description;
    serviceData.price = price;
    serviceData.durationInDay = durationInDay;
    serviceData.isFree = sousType === "oui" ? true : false;

    dispatch(addServiceRequest(serviceData));

    setSubservicesList([]);
    setSousType("non");
    setDescription("");
    setPrice("");
    setDurationInDay("");

    setName(""); // Réinitialisez toujours le champ name indépendamment de sousType
  };

  const idProduct = serviceData ? serviceData._id : null;
  const handleUpdateService = (e) => {
    e.preventDefault();
    e.stopPropagation();

    let serviceData = {
      name: name,
      type: "service",
    };

    serviceData.description = description;
    serviceData.price = price;
    serviceData.durationInDay = durationInDay;
    serviceData.isFree = sousType === "oui" ? true : false;

    dispatch(updateServiceRequest(idProduct, serviceData));

    setSubservicesList([]);
    setSousType("");
    setName("");
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      size="4xl"
      scrollBehavior={"inside"}
      isCentered
    >
      <ModalOverlay
        bg="blackAlpha.300"
        backdropFilter="blur(10px) hue-rotate(90deg)"
      />
      <ModalContent>
        <ModalBody pb={6}>
          <Flex justify="flex-start" p={4}>
            <Text fontSize={{ base: "12px", lg: "24px" }} fontWeight="semibold">
              Add a bundle
            </Text>
          </Flex>
          <Flex direction="column" justify="flex-start" gap={4} p={4}>
            <FormControl isRequired>
              <FormLabel>Name</FormLabel>
              <Input
                value={name}
                onChange={handleNameChange}
                variant="filled"
                placeholder="Product name"
              />
            </FormControl>
            <FormControl isRequired>
              <Flex>
                <FormLabel>Free service ?</FormLabel>
                <RadioGroup
                  defaultValue="non"
                  onChange={handleRadioChange}
                  value={sousType}
                >
                  <Stack spacing={5} direction="row">
                    <Radio colorScheme="green" value="oui">
                      Yes
                    </Radio>
                    <Radio colorScheme="green" value="non">
                      No
                    </Radio>
                  </Stack>
                </RadioGroup>
              </Flex>
            </FormControl>

            <>
              <Flex gap={4}>
                <FormControl isRequired>
                  <FormLabel>Price</FormLabel>
                  <NumberInput
                    size="md"
                    maxW={72}
                    onChange={handlePriceChange}
                    value={price}
                  >
                    <NumberInputField />
                  </NumberInput>
                </FormControl>

                <FormControl isRequired>
                  <FormLabel>Period in days </FormLabel>
                  <NumberInput
                    size="md"
                    maxW={60}
                    onChange={handleDurationInDayChange}
                    value={durationInDay}
                  >
                    <NumberInputField />
                  </NumberInput>
                </FormControl>
                <FormControl isRequired>
                  <FormLabel>Description</FormLabel>
                  <Textarea
                    value={description}
                    onChange={handleDescriptionChange}
                    placeholder="Service description"
                    size="sm"
                    variant="filled"
                  />
                </FormControl>
              </Flex>
            </>
          </Flex>
        </ModalBody>

        <ModalFooter>
          <Flex gap={4}>
            <Button onClick={() => onClose()} colorScheme="red" variant="solid">
              Annuler
            </Button>
            <Button
              onClick={
                serviceData?._id ? handleUpdateService : handleAddService
              }
              colorScheme="green"
              variant="solid"
              isLoading={loading}
              loadingText="En cours..."
            >
              {serviceData?._id ? "Modifier" : "Ajouter"}
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddServiceForm;
