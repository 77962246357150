import React from "react";

// Chakra imports
import {
  Box,
  useColorModeValue,
} from "@chakra-ui/react";

export default function Marketplace() {
  // Chakra Color Mode
  return (
    <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
      {/* Main Fields */}
      
      {/* Delete Product */}
    </Box>
  );
}
