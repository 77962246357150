import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    ModalFooter,
    Button,
    Text,
    Flex,
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    IconButton,
    Avatar,
} from '@chakra-ui/react';
import { FaFileDownload } from "react-icons/fa";
import React from 'react';
import LogoOrange from '../../../../assets/img/CM_ORANGEMONEY.png'
import LogoMTN from '../../../../assets/img/CM_MTNMOBILEMONEY.png'
import moment from 'moment/moment';

const UserDetails = ({ isOpen, onClose, selectedData }) => {
    const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
    return (
        <Modal
            closeOnOverlayClick={false}
            isOpen={isOpen}
            onClose={onClose}
            size='6xl'
            isCentered
        >
            <ModalOverlay />
            <ModalContent>
                <ModalBody pb={6}>
                    <Flex justify="flex-start" mb={8}>
                        <Text fontSize={{ base: "12px", lg: '24px' }} fontWeight="semibold">
                            Historique des transactions
                        </Text>
                    </Flex>
                    <Flex>
                        <TableContainer w='100%'>
                            <Table size='md' variant='simple' color='gray.500' mb='24px'>
                                <Thead>
                                    <Tr>
                                        <Th pe='10px' borderColor={borderColor}>
                                            <Flex
                                                justify="flex-start"
                                                align='center'
                                                fontSize={{ sm: "10px", lg: "12px" }}
                                                color='gray.400'>
                                                Operateur
                                            </Flex>
                                        </Th>
                                        <Th pe='10px' borderColor={borderColor}>
                                            <Flex
                                                justify="flex-start"
                                                align='center'
                                                fontSize={{ sm: "10px", lg: "12px" }}
                                                color='gray.400'>
                                                Numero WhatsApp
                                            </Flex>
                                        </Th>
                                        <Th pe='10px' borderColor={borderColor}>
                                            <Flex
                                                justify="flex-start"
                                                align='center'
                                                fontSize={{ sm: "10px", lg: "12px" }}
                                                color='gray.400'>
                                                Id Transaction
                                            </Flex>

                                        </Th>
                                        <Th pe='10px' borderColor={borderColor}>
                                            <Flex
                                                justify="flex-start"
                                                align='center'
                                                fontSize={{ sm: "10px", lg: "12px" }}
                                                color='gray.400'>
                                                Achat
                                            </Flex>

                                        </Th>
                                        <Th pe='10px' borderColor={borderColor}>
                                            <Flex
                                                justify="flex-start"
                                                align='center'
                                                fontSize={{ sm: "10px", lg: "12px" }}
                                                color='gray.400'>
                                                Expiration
                                            </Flex>

                                        </Th>
                                        <Th pe='10px' borderColor={borderColor}>
                                            <Flex
                                                justify="flex-start"
                                                align='center'
                                                fontSize={{ sm: "10px", lg: "12px" }}
                                                color='gray.400'>
                                                Produit acheté
                                            </Flex>

                                        </Th>
                                        <Th pe='10px' borderColor={borderColor}>
                                            <Flex
                                                justify="flex-start"
                                                align='center'
                                                fontSize={{ sm: "10px", lg: "12px" }}
                                                color='gray.400'>

                                            </Flex>
                                        </Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {selectedData?.subscriptions.map((subscription, index) => (
                                        <Tr key={index}>
                                            <Td>{subscription.operator === 'Orange' ? (<Avatar src={LogoOrange} size="sm" />) : (<Avatar src={LogoMTN} size="sm" />)}</Td>
                                            <Td>{selectedData.phoneNumber}</Td>
                                            <Td>{subscription.transaction_id}</Td>
                                            <Td>{moment(subscription.subscriptionDate).format('YYYY-MM-DD')}</Td>
                                            <Td>{subscription.productType === 'service' ? moment(subscription.expirationDate).format('YYYY-MM-DD') : ''}</Td>
                                            <Td>{subscription.productId.name}</Td>
                                            <Td>
                                                <IconButton
                                                    aria-label='Call Segun'
                                                    size='md'
                                                    background='transparent'
                                                    icon={<FaFileDownload />}
                                                />
                                            </Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                        </TableContainer>
                    </Flex>

                </ModalBody>

                <ModalFooter>
                    <Button onClick={onClose} colorScheme='green' variant='solid'>Fermer</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default UserDetails;
