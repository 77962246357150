import React, { useEffect, useState } from "react";

// Chakra imports
import { Box, Button, Flex } from "@chakra-ui/react";
import Cards from "./components/Cards";
import IconBox from "components/icons/IconBox";
import { Icon, useColorModeValue } from "@chakra-ui/react";
import { GiTakeMyMoney } from "react-icons/gi";

import Card from "components/card/Card.js";

// Assets
import TransactionsOverview from "./components/TransactionsView";
import { connect, useDispatch } from "react-redux";
import { fetchTransactionsRequest } from "redux/actions";
import { fetchTotalAmountRequest } from "redux/actions";
import Loading from "components/loading";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";

const Transactions = ({ transactions, loading, totalAmount }) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const limit = 10;
  const maxPage = Math.ceil(transactions.length);

  useEffect(() => {
    dispatch(fetchTransactionsRequest({ page, limit }));
    dispatch(fetchTotalAmountRequest());
  }, [dispatch, page, limit]);

  const handlePageClick = (newPage) => {
    if (newPage >= 1 && newPage <= maxPage) {
      setPage(newPage);
    }
  };

  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const brandColor = useColorModeValue("brand.500", "white");
  return (
    <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
      {loading ? (
        <>
          <Loading />
        </>
      ) : (
        <>
          <Box w="40%">
            {totalAmount?.map((total, index) => (
              <Cards
                startContent={
                  <IconBox
                    w="56px"
                    h="56px"
                    bg={boxBg}
                    icon={
                      <Icon
                        w="45px"
                        h="45px"
                        as={GiTakeMyMoney}
                        color={brandColor}
                      />
                    }
                  />
                }
                name="Total amount of successful transactions"
                value={`${total.amount} XAF`}
              />
            ))}
          </Box>
          <Flex
            flexDirection="column"
            gridArea={{ xl: "1 / 3 / 2 / 4", "2xl": "1 / 2 / 2 / 3" }}
            mt={4}
          >
            <Card px="0px" mb="20px">
              <TransactionsOverview
                transactions={transactions}
                loading={loading}
              />
              <Box mt="4">
                <Button
                  leftIcon={<ChevronLeftIcon />}
                  isDisabled={page === 1}
                  onClick={() => handlePageClick(page - 1)}
                >
                  Préc
                </Button>
                <Button
                  ml="2"
                  isDisabled={page === maxPage || maxPage < limit}
                  onClick={() => handlePageClick(page + 1)}
                >
                  Suiv
                  <ChevronRightIcon />
                </Button>
              </Box>
            </Card>
          </Flex>
        </>
      )}
    </Box>
  );
};
const mapStateToProps = ({ DashboardReducer }) => ({
  transactions: DashboardReducer.transactions,
  totalAmount: DashboardReducer.totalAmount,
  loading: DashboardReducer.loading,
  error: DashboardReducer.error,
});

export default connect(mapStateToProps)(Transactions);
