import {
  Avatar,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
import LogoOrange from '../../../../assets/img/CM_ORANGEMONEY.png'
import LogoMTN from '../../../../assets/img/CM_MTNMOBILEMONEY.png'
import Loading from "components/loading";

function TopCreatorTable(props) {
  const { transactions, loading } = props;
  const textColor = useColorModeValue("navy.700", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  return (
    <>
      <Flex
        direction='column'
        w='100%'
        overflowX={{ sm: "scroll", lg: "hidden" }}>
        {loading ? <>
          <Loading />
        </> : <>
          <TableContainer >
            <Table size='sm' variant='simple' color='gray.500' mb='24px'>
              <Thead>
                <Tr>
                  <Th borderColor={borderColor}>
                    <Flex
                      fontSize={{ sm: "10px", lg: "12px" }}
                      color='gray.400'>
                      Operator
                    </Flex>
                  </Th>
                  <Th borderColor={borderColor}>
                    <Flex
                      fontSize={{ sm: "10px", lg: "12px" }}
                      color='gray.400'>
                      Number of transaction
                    </Flex>
                  </Th>
                  <Th borderColor={borderColor}>
                    <Flex
                      fontSize={{ sm: "10px", lg: "12px" }}
                      color='gray.400'>
                      Customer
                    </Flex>
                  </Th>
                  <Th borderColor={borderColor}>
                    <Flex
                      fontSize={{ sm: "10px", lg: "12px" }}
                      color='gray.400'>
                      Amount
                    </Flex>
                  </Th>
                  <Th borderColor={borderColor}>
                    <Flex
                      fontSize={{ sm: "10px", lg: "12px" }}
                      color='gray.400'>
                      Id Transaction
                    </Flex>
                  </Th>
                  <Th borderColor={borderColor}>
                    <Flex
                      fontSize={{ sm: "10px", lg: "12px" }}
                      color='gray.400'>
                      Bundle
                    </Flex>
                  </Th>
                  <Th borderColor={borderColor}>
                    <Flex
                      fontSize={{ sm: "10px", lg: "12px" }}
                      color='gray.400'>
                      Status
                    </Flex>
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {transactions.map((transaction, index) => (
                  <Tr key={index}>
                    <Td color={textColor} fontSize='sm' fontWeight='700'>
                      {transaction.operator === 'Orange' ?
                        <Flex align='center'>
                          {/* <Avatar size="sm" name={transaction.operator} src={LogoOrange} /> */}
                          <Text color={textColor} fontSize='sm' fontWeight='700' ml={4}>
                            {transaction?.operator}
                          </Text>
                        </Flex> :
                        <Flex align='center'>
                          <Avatar size="sm" name={transaction.operator} src={LogoMTN} />
                          <Text color={textColor} fontSize='sm' fontWeight='700' ml={4}>
                            {transaction?.operator}
                          </Text>
                        </Flex>
                      }
                    </Td>
                    <Td color={textColor} fontSize='sm' fontWeight='700'>
                      {transaction?.transactionNumber}
                    </Td>
                    <Td color={textColor} fontSize='sm' fontWeight='700'>
                      {transaction?.userData?.name}
                    </Td>
                    <Td color={textColor} fontSize='sm' fontWeight='700'>
                      {transaction?.amount} XAF
                    </Td>
                    <Td color={textColor} fontSize='sm' fontWeight='700'>
                      {transaction?.transactionId}
                    </Td>
                    <Td color={textColor} fontSize='sm' fontWeight='700' overflowWrap='normal'>
                    {transaction.isOption ? transaction.optionId.substring(0, 23)+ "..." : transaction.productId.substring(0, 23)+ "..."}
                    </Td>
                    <Td color={textColor} fontSize='sm' fontWeight='700'>
                      {transaction?.status}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </>}
      </Flex>
    </>
  );
}

export default TopCreatorTable;
