import { call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import * as types from './types';
import { url } from '../urlLoader';
import { deleteRequest, getRequest, postRequest, postRequestFormData, postUnauthRequest, putRequest, putRequestFormData } from '../helper/api';
import toast, { Toaster } from 'react-hot-toast';

function* fetchTransactionsRequest(action) {
  const {page, limit} = action.payload
  try {
    let link = `${url}/api/v1/transactions/all-transactions?page=${page}&limit=${limit}`;

    const data = yield getRequest(link);

    if (data.success) {
      yield put({ type: types.GET_TRANSACTIONS_SUCCESS, payload: data });
    } else {
      yield put({ type: types.GET_TRANSACTIONS_FAILED, payload: "echec recuperation des données" });
    }

  } catch (error) {
    console.log(error);
    yield put({ type: types.GET_TRANSACTIONS_FAILED, payload: error });
  }
}

function* fetchUsersRequest(action) {
  const {page, limit} = action.payload
  try {
    let link = `${url}/api/v1/user/all-users?page=${page}&limit=${limit}`;

    const data = yield getRequest(link);

    if (data.success) {
      yield put({ type: types.GET_USERS_SUCCESS, payload: data });
    } else {
      yield put({ type: types.GET_USERS_FAILED, payload: "echec recuperation des données" });
    }

  } catch (error) {
    console.log(error);
    yield put({ type: types.GET_USERS_FAILED, payload: error });
  }
}

function* updateUsersRequest(action) {
  const { phoneNumber, updatedData } = action.payload;
  try {
    let link = `${url}/api/v1/user/update-user`;

    const data = yield putRequest(link, JSON.stringify({
      phoneNumber: phoneNumber,
      role: updatedData.role,
    }));

    if (data?._id) {
      yield put({ type: types.UPDATE_USERS_ROLE_SUCCESS, payload: data });
    } else {
      yield put({ type: types.UPDATE_USERS_ROLE_FAILED, payload: "Échec de la mise à jour des données" });
    }
  } catch (error) {
    console.log(error);
    yield put({ type: types.UPDATE_USERS_ROLE_FAILED, payload: error });
  }
}

function* fetchProductsRequest(action) {
  const {page, limit} = action.payload;
  console.log(action.payload, 'hhhhh')
  try {
    let link = `${url}/api/v1/product/all-products?page=${page}&limit=${limit}`;

    const data = yield getRequest(link);

    if (data.success) {
      yield put({ type: types.GET_PRODUCTS_SUCCESS, payload: data });
    } else {
      yield put({ type: types.GET_PRODUCTS_FAILED, payload: "echec recuperation des données" });
    }

  } catch (error) {
    console.log(error);
    yield put({ type: types.GET_PRODUCTS_FAILED, payload: error });
  }
}

function* addProductRequest(action) {
  try {
    // Définir le lien de l'API
    const link = `${url}/api/v1/signal/create-signal`;

    const data = yield postRequestFormData(link, action.payload);

    if (data) {
      toast.success("Produit ajouté avec success");
      yield put({ type: types.ADD_PRODUCT_SUCCESS, payload: data });
      yield call(fetchProductsRequest, { payload: {page: 0, limit: 0} });
    } else {
      toast.error("Aucune donnée n'a été ajouté.");
      yield put({ type: types.ADD_PRODUCT_FAILED, payload: "Échec lors de la récupération des données" });
    }

  } catch (error) {
    toast.error("Aucune donnée n'a été ajouté.");
    console.error(error);
    yield put({ type: types.ADD_PRODUCT_FAILED, payload: error.message || "Une erreur s'est produite" });
  }
}

function* addServiceRequest(action) {
  try {
    // Définir le lien de l'API
    const link = `${url}/api/v1/product/create-service`;

    const data = yield postRequest(link, JSON.stringify(action.payload));

    if (data) {
      toast.success("Service ajouté avec success");
      yield put({ type: types.ADD_SERVICE_SUCCESS, payload: data });
      yield call(fetchProductsRequest, { payload: {page: 0, limit: 0} });
    } else {
      toast.error("Aucune donnée n'a été ajouté.");
      yield put({ type: types.ADD_SERVICE_FAILED, payload: "Échec lors de la récupération des données" });
    }

  } catch (error) {
    toast.error("Aucune donnée n'a été ajouté.");
    console.error(error);
    yield put({ type: types.ADD_SERVICE_FAILED, payload: error.message || "Une erreur s'est produite" });
  }
}

function* deleteProductRequest(action) {
  const { id } = action.payload;

  try {
    // Définir le lien de l'API
    const link = `${url}/api/v1/product/delete/${id}`;

    // Utiliser postRequestFormData pour envoyer le FormData
    const data = yield deleteRequest(link);
    if (data) {
      toast.success("Produit supprimé avec success");
      yield put({ type: types.DELETE_PRODUCT_SUCCESS, payload: data });
      yield call(fetchProductsRequest, { payload: {} });
    } else {
      toast.error("Aucune donnée n'a été supprimé.");
      yield put({ type: types.DELETE_PRODUCT_FAILED, payload: "Échec lors de la suppression des données" });
    }

  } catch (error) {
    console.error(error);
    toast.error("Aucune donnée n'a été supprimé.");
    yield put({ type: types.DELETE_PRODUCT_FAILED, payload: error.message || "Une erreur s'est produite" });
  }
}

function* updateProductRequest(action) {
  const { id } = action.payload;

  try {
    // Définir le lien de l'API
    const link = `${url}/api/v1/product/update-product/${id}`;

    // Utiliser postRequestFormData pour envoyer le FormData
    const data = yield putRequestFormData(link, action.payload.productData);

    if (data) {
      toast.success("Produit modifié avec success");
      yield put({ type: types.UPDATE_PRODUCT_SUCCESS, payload: data.data });
      yield call(fetchProductsRequest, { payload: {} });
    } else {
      toast.error("Aucune donnée n'a été modifié.");
      yield put({ type: types.UPDATE_PRODUCT_FAILED, payload: "Échec lors de la modification des données" });
    }

  } catch (error) {
    console.error(error);
    toast.error("Aucune donnée n'a été modifié.");
    yield put({ type: types.UPDATE_PRODUCT_FAILED, payload: error.message || "Une erreur s'est produite" });
  }
}

function* updateServiceRequest(action) {
  const { id } = action.payload;

  try {
    // Définir le lien de l'API
    const link = `${url}/api/v1/product/update-service/${id}`;

    // Utiliser postRequestFormData pour envoyer le FormData
    const data = yield putRequest(link, JSON.stringify(action.payload.serviceData));

    if (data) {
      toast.success("Service modifié avec success");
      yield put({ type: types.UPDATE_SERVICE_SUCCESS, payload: data.data });
      yield call(fetchProductsRequest, { payload: {page: 0, limit: 0} });
    } else {
      toast.error("Aucune donnée n'a été modifié.");
      yield put({ type: types.UPDATE_SERVICE_FAILED, payload: "Échec lors de la modification des données" });
    }

  } catch (error) {
    console.error(error);
    toast.error("Aucune donnée n'a été modifié.");
    yield put({ type: types.UPDATE_SERVICE_FAILED, payload: error.message || "Une erreur s'est produite" });
  }
}

function* deleteSubserviceRequest(action) {
  console.log(action.payload)
  const {id, optionId} = action.payload
    try {
      // Définir le lien de l'API
      const link = `${url}/api/v1/product/remove-option/${id}/${optionId}`;
  
      // Utiliser postRequestFormData pour envoyer le FormData
      const data = yield deleteRequest(link); 
  
      if (data.success) {
        toast.success("Sous-service retiré de la galerie");
        yield put({ type: types.DELETE_SUBSERVICE_SUCCESS, payload: data });
      } else {
        toast.error("Aucun sous-service n'a été supprimé.");
        yield put({ type: types.DELETE_SUBSERVICE_FAILED, payload: "Échec lors de la suppression des données" });
      }
  
    } catch (error) {
      console.error(error);
      toast.error("Le sous-service n'a pas été retiré");
      yield put({ type: types.DELETE_SUBSERVICE_FAILED, payload: error.message || "Une erreur s'est produite" });
    }
  }

function* fetchEventsRequest(action) {
  const {page, limit} = action.payload
  try {
    let link = `${url}/api/v1/events/all-events?page=${page}&limit=${limit}`;

    const data = yield getRequest(link);

    if (data.success) {
      yield put({ type: types.GET_EVENTS_SUCCESS, payload: data });
    } else {
      yield put({ type: types.GET_EVENTS_FAILED, payload: "echec recuperation des données" });
    }

  } catch (error) {
    console.log(error);
    yield put({ type: types.GET_EVENTS_FAILED, payload: error });
  }
}

function* fetchEventsUsersRequest(action) {
  const id = action.payload;

  try {
    let link = `${url}/api/v1/events/users/${id}`;

    const data = yield getRequest(link);


    if (data) {
      yield put({ type: types.GET_EVENTS_USERS_SUCCESS, payload: data });
    } else {
      yield put({ type: types.GET_EVENTS_USERS_FAILED, payload: "echec recuperation des données" });
    }

  } catch (error) {
    console.log(error);
    yield put({ type: types.GET_EVENTS_FAILED, payload: error });
  }
}

function* addEventsRequest(action) {

  try {
    // Définir le lien de l'API
    const link = `${url}/api/v1/events/create-event`;

    // Utiliser postRequestFormData pour envoyer le FormData
    const data = yield postRequestFormData(link, action.payload);

    if (data) {
      toast.success("Evenement ajoute avec success");
      yield put({ type: types.ADD_EVENTS_SUCCESS, payload: data });
      yield call(fetchEventsRequest, { payload: {} });
    } else {
      toast.error("Aucune donnée n'a été sauvegardée.");
      yield put({ type: types.ADD_EVENTS_FAILED, payload: "Échec lors de la récupération des données" });
    }

  } catch (error) {
    console.error(error);
    toast.error("Aucune donnée n'a été sauvegardée.");
    yield put({ type: types.ADD_EVENTS_FAILED, payload: error.message || "Une erreur s'est produite" });
  }
}

function* updateEventsRequest(action) {
  const { id } = action.payload;

  try {
    // Définir le lien de l'API
    const link = `${url}/api/v1/events/update/${id}`;

    // Utiliser postRequestFormData pour envoyer le FormData
    const data = yield putRequestFormData(link, action.payload.eventData);

    if (data) {
      toast.success("Evenement modifié avec success");
      yield put({ type: types.ADD_EVENTS_SUCCESS, payload: data.data });
      yield call(fetchEventsRequest, { payload: {} });
    } else {
      toast.error("Aucune donnée n'a été modifié.");
      yield put({ type: types.ADD_EVENTS_FAILED, payload: "Échec lors de la modification des données" });
    }

  } catch (error) {
    console.error(error);
    toast.error("Aucune donnée n'a été modifié.");
    yield put({ type: types.ADD_EVENTS_FAILED, payload: error.message || "Une erreur s'est produite" });
  }
}

function* deleteEventsRequest(action) {
  const { id } = action.payload;

  try {
    // Définir le lien de l'API
    const link = `${url}/api/v1/events/delete/${id}`;

    // Utiliser postRequestFormData pour envoyer le FormData
    const data = yield deleteRequest(link);
    if (data) {
      toast.success("Evenement supprimé avec success");
      yield put({ type: types.DELETE_EVENTS_SUCCESS, payload: data });
      yield call(fetchEventsRequest, { payload: {} });
    } else {
      toast.error("Aucune donnée n'a été supprimé.");
      yield put({ type: types.DELETE_EVENTS_FAILED, payload: "Échec lors de la suppression des données" });
    }

  } catch (error) {
    console.error(error);
    toast.error("Aucune donnée n'a été supprimé.");
    yield put({ type: types.DELETE_EVENTS_FAILED, payload: error.message || "Une erreur s'est produite" });
  }
}

function* deleteImageGalleryRequest(action) {
const {eventId, imageUrl} = action.payload
  try {
    // Définir le lien de l'API
    const link = `${url}/api/v1/events/remove-image/${eventId}`;

    // Utiliser postRequestFormData pour envoyer le FormData
    const data = yield postRequest(link, JSON.stringify({imageUrl: imageUrl})); 

    if (data) {
      toast.success("Image retiré de la galerie");
      yield put({ type: types.DELETE_IMAGE_GALLERY_SUCCESS, payload: data.data });
      yield call(fetchEventsRequest, { payload: {} });
    } else {
      toast.error("Aucune donnée n'a été supprimé.");
      yield put({ type: types.DELETE_IMAGE_GALLERY_FAILED, payload: "Échec lors de la suppression des données" });
    }

  } catch (error) {
    console.error(error);
    toast.error("L'image n'a pas été retiré");
    yield put({ type: types.DELETE_IMAGE_GALLERY_FAILED, payload: error.message || "Une erreur s'est produite" });
  }
}

function* fetchTotalAmountRequest() {
  try {
    let link = `${url}/api/v1/transactions/total-amount-transactions`;

    const data = yield getRequest(link);

    if (data) {
      yield put({ type: types.GET_TOTAL_AMOUNT_SUCCESS, payload: data.totalAmount });
    } else {
      yield put({ type: types.GET_TOTAL_AMOUNT_FAILED, payload: "echec recuperation des données" });
    }

  } catch (error) {
    console.log(error);
    yield put({ type: types.GET_TOTAL_AMOUNT_FAILED, payload: error });
  }
}

function* loginRequest(action) {
  const { phoneNumber, password } = action.payload;
  try {
    let link = `${url}/api/v1/user/login`;

    const data = yield postUnauthRequest(link, JSON.stringify({
      phoneNumber: phoneNumber,
      password: password
    }));

    if (data && data.token && data.user.role === 'admin') {
      toast.success("Connexion reussie");
      localStorage.setItem('token', data.token);
      localStorage.setItem('userName', data.user.name);
      yield put({ type: types.LOGIN_SUCCESS, payload: { token: data.token, userName: data.user.name } });
    } else {
      toast.error("Connexion echouée.");
      yield put({ type: types.LOGIN_FAILURE, payload: data.message });
    }

  } catch (error) {
    console.log(error);
    toast.error("Connexion echouée.");
    yield put({ type: types.LOGIN_FAILURE, payload: error });
  }
}



export default function* DashboardSaga() {
  yield takeLatest(types.GET_TRANSACTIONS_REQUEST, fetchTransactionsRequest);
  yield takeLatest(types.GET_USERS_REQUEST, fetchUsersRequest);
  yield takeLatest(types.UPDATE_USERS_ROLE_REQUEST, updateUsersRequest);
  yield takeLatest(types.GET_PRODUCTS_REQUEST, fetchProductsRequest);
  yield takeLatest(types.ADD_SERVICE_REQUEST, addServiceRequest);
  yield takeLatest(types.ADD_PRODUCT_REQUEST, addProductRequest);
  yield takeLatest(types.DELETE_PRODUCT_REQUEST, deleteProductRequest);
  yield takeLatest(types.UPDATE_PRODUCT_REQUEST, updateProductRequest);
  yield takeLatest(types.UPDATE_SERVICE_REQUEST, updateServiceRequest);
  yield takeLatest(types.DELETE_SUBSERVICE_REQUEST, deleteSubserviceRequest);
  yield takeLatest(types.GET_EVENTS_REQUEST, fetchEventsRequest);
  yield takeLatest(types.GET_EVENTS_USERS_REQUEST, fetchEventsUsersRequest);
  yield takeLatest(types.ADD_EVENTS_REQUEST, addEventsRequest);
  yield takeLatest(types.UPDATE_EVENTS_REQUEST, updateEventsRequest);
  yield takeLatest(types.DELETE_EVENTS_REQUEST, deleteEventsRequest);
  yield takeLatest(types.DELETE_IMAGE_GALLERY_REQUEST, deleteImageGalleryRequest);
  yield takeLatest(types.GET_TOTAL_AMOUNT_REQUEST, fetchTotalAmountRequest);
  yield takeLatest(types.LOGIN_REQUEST, loginRequest);


}




