import {
    Flex,
    Image,
    Progress,
  } from "@chakra-ui/react";
  import React from "react";
  
  function Loading(props) {
    return (
        <Flex direction='column' justifyContent='center' align='center' height={{ sm: "10rem", lg: "30rem" }}>
        <Image src="https://bibemella.isomora.com/wp-content/uploads/2023/10/logo-fbe.png" width={50} height={50} mb={4}/>
        <Progress size="xs" isIndeterminate />
      </Flex>
    );
  }
  
  export default Loading;
  