
import * as types from './types';

const INITIAL_STATE = {
  transactions: [],
  totalTransaction: 0,
  users: [],
  totalUsers: 0,
  products: [],
  totalProducts: 0,
  events: [],
  totalEvents: 0,
  totalAmount: [],
  deleting: false,
  userEvent: null,
  token: localStorage.getItem('token') || null,
  userAuth: null,
  loading: false,
  error: null,
  loading_action: false,
  closeModal: false,
};

function DashboardReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.GET_TRANSACTIONS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case types.GET_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        transactions: action.payload.transactions,
        totalTransaction: action.payload.total
      };
    case types.GET_TRANSACTIONS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.toString(),
      };
    case types.GET_USERS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case types.GET_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.payload.users,
        totalUsers: action.payload.totalCount
      };
    case types.GET_USERS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.toString(),
      };
    case types.UPDATE_USERS_ROLE_REQUEST:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case types.UPDATE_USERS_ROLE_SUCCESS:
      const updatedUsers = state.users.map((user) =>
        user.phoneNumber === action.payload.phoneNumber
          ? action.payload
          : user
      );

      return {
        ...state,
        users: updatedUsers,
        loading: false,
      };
    case types.UPDATE_USERS_ROLE_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.toString(),
      };
    case types.GET_PRODUCTS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case types.GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        products: action.payload.products,
        totalProducts: action.payload.total
      };
    case types.GET_PRODUCTS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.toString(),
      };
      case types.ADD_SERVICE_REQUEST:
      return {
        ...state,
        loading_action: true,
        error: null,
        closeModal: false,
      };
    case types.ADD_SERVICE_SUCCESS:
      return {
        ...state,
        loading_action: false,
        error: null,
        closeModal: true,
      };
    case types.ADD_SERVICE_FAILED:
      return {
        ...state,
        loading_action: false,
        error: action.payload,
        closeModal: false,
      };
      case types.ADD_PRODUCT_REQUEST:
      return {
        ...state,
        loading_action: true,
        error: null,
        closeModal: false,
      };
    case types.ADD_PRODUCT_SUCCESS:
      return {
        ...state,
        loading_action: false,
        error: null,
        closeModal: true,
      };
    case types.ADD_PRODUCT_FAILED:
      return {
        ...state,
        loading_action: false,
        error: action.payload,
        closeModal: false,
      };
      case types.DELETE_PRODUCT_REQUEST:
      return {
        ...state,
        loading_action: true,
        error: null,
        closeModal: false,
      };
    case types.DELETE_PRODUCT_SUCCESS:
      return {
        ...state,
        loading_action: false,
        error: null,
        closeModal: true,
      };
    case types.DELETE_PRODUCT_FAILED:
      return {
        ...state,
        loading_action: false,
        error: action.payload,
        closeModal: false,
      };
      case types.UPDATE_PRODUCT_REQUEST:
      return {
        ...state,
        loading_action: true,
        error: null,
        closeModal: false,
      };
    case types.UPDATE_PRODUCT_SUCCESS:
      return {
        ...state,
        loading_action: false,
        error: null,
        closeModal: true,
      };
    case types.UPDATE_PRODUCT_FAILED:
      return {
        ...state,
        loading_action: false,
        error: action.payload,
        closeModal: false,
      };
      case types.UPDATE_SERVICE_REQUEST:
      return {
        ...state,
        loading_action: true,
        error: null,
        closeModal: false,
      };
    case types.UPDATE_SERVICE_SUCCESS:
      return {
        ...state,
        loading_action: false,
        error: null,
        closeModal: true,
      };
    case types.UPDATE_SERVICE_FAILED:
      return {
        ...state,
        loading_action: false,
        error: action.payload,
        closeModal: false,
      };
      case types.DELETE_SUBSERVICE_REQUEST:
      return {
        ...state,
        deleting: true,
        error: null,
      };
    case types.DELETE_SUBSERVICE_SUCCESS:
      return {
        ...state,
        deleting: false,
        error: null,
      };
    case types.DELETE_SUBSERVICE_FAILED:
      return {
        ...state,
        deleting: false,
        error: action.payload,
      };
    case types.GET_TOTAL_AMOUNT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case types.GET_TOTAL_AMOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        totalAmount: action.payload,
      };
    case types.GET_TOTAL_AMOUNT_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.toString(),
      };
      case types.GET_EVENTS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case types.GET_EVENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        events: action.payload.events,
        totalEvents: action.payload.total
      };
    case types.GET_EVENTS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.toString(),
      };
      case types.GET_EVENTS_USERS_REQUEST:
      return {
        ...state,
        loading_action: true,
        error: null,
      };
    case types.GET_EVENTS_USERS_SUCCESS:
      return {
        ...state,
        loading_action: false,
        userEvent: action.payload,
      };
    case types.GET_EVENTS_USERS_FAILED:
      return {
        ...state,
        loading_action: false,
        error: action.payload.toString(),
      };
      case types.ADD_EVENTS_REQUEST:
      return {
        ...state,
        loading_action: true,
        error: null,
        closeModal: false,
      };
    case types.ADD_EVENTS_SUCCESS:
      return {
        ...state,
        loading_action: false,
        error: null,
        closeModal: true,
      };
    case types.ADD_EVENTS_FAILED:
      return {
        ...state,
        loading_action: false,
        error: action.payload,
        closeModal: false,
      };
      case types.UPDATE_EVENTS_REQUEST:
      return {
        ...state,
        loading_action: true,
        error: null,
        closeModal: false,
      };
    case types.UPDATE_EVENTS_SUCCESS:
      return {
        ...state,
        loading_action: false,
        error: null,
        closeModal: true,
      };
    case types.UPDATE_EVENTS_FAILED:
      return {
        ...state,
        loading_action: false,
        error: action.payload,
        closeModal: false,
      };
      case types.DELETE_EVENTS_REQUEST:
      return {
        ...state,
        loading_action: true,
        error: null,
        closeModal: false,
      };
    case types.DELETE_EVENTS_SUCCESS:
      return {
        ...state,
        loading_action: false,
        error: null,
        closeModal: true,
      };
    case types.DELETE_EVENTS_FAILED:
      return {
        ...state,
        loading_action: false,
        error: action.payload,
        closeModal: false,
      };
      case types.DELETE_IMAGE_GALLERY_REQUEST:
      return {
        ...state,
        deleting: true,
        error: null,
      };
    case types.DELETE_IMAGE_GALLERY_SUCCESS:
      return {
        ...state,
        deleting: false,
        error: null,
      };
    case types.DELETE_IMAGE_GALLERY_FAILED:
      return {
        ...state,
        deleting: false,
        error: action.payload,
      };
      case types.LOGIN_SUCCESS:
      return {
        ...state,
        token: action.payload,
        userAuth: action.payload,
        error: null,
      };
      case types.LOGIN_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
      case types.LOGOUT_SUCCESS:
      return {
        ...state,
        userAuth: null,
      };
    default:
      return state;
  }
}
export default DashboardReducer;
