import {
  Box,
  Button,
  Flex,
  IconButton,
  Image,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  WrapItem,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { MdAddCircle } from "react-icons/md";

import Loading from "components/loading";
import AddServiceForm from "./AddServiceForm";
import { ChevronLeftIcon, ChevronRightIcon, DeleteIcon, EditIcon } from "@chakra-ui/icons";
import DeleteProduct from "./DeleteProduct";

function TopCreatorTable({ products, loading, limit }) {
  const [hoveredRowIndex, setHoveredRowIndex] = useState(null);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(false);
  const [page, setPage] = useState(1);

  const handleUpdateService = (val) => {
    setSelectedProduct(val)
    setIsFormOpen(true);
  };
  const textColor = useColorModeValue("navy.700", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  const filterType = (array = [], type) => {
    return array.filter((ele) => ele.type === type);
  };

  const maxPage = Math.ceil(filterType(products, "service").length);

  const handlePageClick = (newPage) => {
    if (newPage >= 1 && newPage <= maxPage) {
      setPage(newPage);
    }
  };

  const dataColor = useColorModeValue("navy.700", "white");
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Flex
        direction='column'
        w='100%'
        overflowX={{ sm: "scroll", lg: "hidden" }}>
        <Flex
          align={{ sm: "flex-start", lg: "center" }}
          justify='space-between'
          w='100%'
          px='22px'
          pb='20px'
          mb='10px'
          boxShadow='0px 40px 58px -20px rgba(112, 144, 176, 0.26)'>
          <Text color={textColor} fontSize='xl' fontWeight='600'>
            Listes of bundles
          </Text>
          <WrapItem>
            <Button
              rightIcon={<MdAddCircle />}
              colorScheme="whatsapp"
              onClick={() => {
                onOpen();
              }}>
              Ajouter
            </Button>
            <AddServiceForm isOpen={isOpen} onClose={onClose} />
          </WrapItem>
        </Flex>
        {loading ? <>
          <Loading />
        </> : <>
          <>
            {filterType(products, "service").length === 0 ? (
              <Flex
                direction="column"
                justifyContent="center"
                align="center"
                height={{ sm: "10rem", lg: "30rem" }}
              >
                <Image
                  src="https://bibemella.isomora.com/wp-content/uploads/2023/10/logo-fbe.png"
                  width={50}
                  height={50}
                  mb={4}
                />
                <Text color={dataColor} fontSize='sm'>Aucun service disponible</Text>
              </Flex>
            ) : (
              <>

                <TableContainer>
                  <Table size="md" variant="simple" color="gray.500" mb="24px">
                    <Thead>
                      <Tr>
                        <Th borderColor={borderColor}>
                          <Flex
                            fontSize={{ sm: "10px", lg: "12px" }}
                            color="gray.400">
                            Nom
                          </Flex>
                        </Th>
                        <Th borderColor={borderColor}>
                          <Flex
                            fontSize={{ sm: "10px", lg: "12px" }}
                            color="gray.400">
                            Prix
                          </Flex>
                        </Th>
                        <Th borderColor={borderColor}>
                          <Flex
                            fontSize={{ sm: "10px", lg: "12px" }}
                            color="gray.400">
                            Durée (en jour)
                          </Flex>
                        </Th>
                        <Th borderColor={borderColor}>
                          <Flex
                            fontSize={{ sm: "10px", lg: "12px" }}
                            color="gray.400" position='absolute'>
                            Actions
                          </Flex>
                        </Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {filterType(products, "service")?.map((product, index) => (
                        <Tr
                          key={index}
                          onMouseEnter={() => setHoveredRowIndex(index)}
                          onMouseLeave={() => setHoveredRowIndex(null)}>
                          <Td>{product.name}</Td>
                          <Td>{product.price}</Td>
                          <Td>{product.durationInDay}</Td>
                          <Td>
                            {hoveredRowIndex === index && (
                              <Flex align="center" justify='center' >
                                <Flex position='absolute' gap={4}>
                                  <IconButton
                                    isRound={true}
                                    variant="outline"
                                    colorScheme="green"
                                    aria-label="Done"
                                    fontSize="15px"
                                    icon={<EditIcon />}
                                    onClick={() => handleUpdateService(product)}
                                  />
                                  <DeleteProduct product={product?._id} />
                                </Flex>
                                <AddServiceForm
                                  isOpen={isFormOpen}
                                  onClose={() => setIsFormOpen(false)}
                                  serviceData={selectedProduct}
                                />
                              </Flex>
                            )}
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>

                <Box mt="4">
                  <Button
                    leftIcon={<ChevronLeftIcon />}
                    isDisabled={page === 1}
                    onClick={() => handlePageClick(page - 1)}
                  >
                    Préc
                  </Button>
                  <Button
                    ml="2"
                    isDisabled={page === maxPage || maxPage < limit}
                    onClick={() => handlePageClick(page + 1)}
                  >
                    Suiv
                    <ChevronRightIcon />
                  </Button>
                </Box>
              </>
            )}
          </>



        </>}

      </Flex>
    </>
  );
}

export default TopCreatorTable;
