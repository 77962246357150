import {
  Flex,
  Switch,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import UserDetails from "./UserDetails";
import Loading from "components/loading";
import { useDispatch } from "react-redux";
import { updateUsersRequest } from "redux/actions";

const TopCreatorTable = ({ users, loading }) => {
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();

  const handleRowClick = (index) => {
    setSelectedRowIndex(index);
    setIsModalOpen(true);
  };

  const handleSwitchToggle = async (index) => {
  // Dispatchez l'action pour mettre à jour le rôle de l'utilisateur
  await dispatch(updateUsersRequest(index.phoneNumber, {role: index.role==='admin' ? 'user' : 'admin'}));
};

  const textColor = useColorModeValue("navy.700", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  return (
    <>
      <Flex
        direction='column'
        w='100%'
        overflowX={{ sm: "scroll", lg: "hidden" }}>
        {loading ? <>
          <Loading />
        </> : <>
          <TableContainer >
            <Table size='md' variant='simple' color='gray.500' mb='24px'>
              <Thead>
                <Tr>
                  <Th borderColor={borderColor}>
                    <Flex
                      justify='space-between'
                      align='center'
                      fontSize={{ sm: "10px", lg: "12px" }}
                      color='gray.400'>
                      Username WhatsApp
                    </Flex>
                  </Th>
                  <Th borderColor={borderColor}>
                    <Flex
                      justify='space-between'
                      align='center'
                      fontSize={{ sm: "10px", lg: "12px" }}
                      color='gray.400'>
                      Numero WhatsApp
                    </Flex>

                  </Th>
                  <Th borderColor={borderColor}>
                    <Flex
                      justify='space-between'
                      align='center'
                      fontSize={{ sm: "10px", lg: "12px" }}
                      color='gray.400'>
                      Indicateur d'engagement
                    </Flex>
                  </Th>
                  <Th borderColor={borderColor}>
                    <Flex
                      justify='space-between'
                      align='center'
                      fontSize={{ sm: "10px", lg: "12px" }}
                      color='gray.400'>
                      Username Ejara
                    </Flex>
                  </Th>
                  <Th borderColor={borderColor}>
                    <Flex
                      justify='space-between'
                      align='center'
                      fontSize={{ sm: "10px", lg: "12px" }}
                      color='gray.400'>
                      Administrateur
                    </Flex>
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {users?.map((user, index) => (
                  <Tr cursor="pointer" key={index} bg={index === selectedRowIndex ? 'gray.200' : 'transparent'}>
                    <Td color={textColor} fontSize='sm' fontWeight='700' onClick={() => handleRowClick(index)}>
                      {user?.name}
                    </Td>
                    <Td color={textColor} fontSize='sm' fontWeight='700' onClick={() => handleRowClick(index)}>
                      {user?.phoneNumber}
                    </Td>
                    <Td color={textColor} fontSize='sm' fontWeight='700' onClick={() => handleRowClick(index)}>
                      {user.engagementLevel}
                    </Td>
                    <Td color={textColor} fontSize='sm' fontWeight='700' onClick={() => handleRowClick(index)}>
                      {user?.username_ejara}
                    </Td>
                    <Td>
                      <Switch
                        isChecked = {user.role==='admin'}
                        onChange={() => handleSwitchToggle(user)}
                        colorScheme="green"
                      />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
          <UserDetails isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} selectedData={selectedRowIndex !== null ? users[selectedRowIndex] : null} />
        </>
        }

      </Flex>
    </>
  );
}

export default TopCreatorTable;
