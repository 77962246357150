import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    ModalFooter,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Input,
    Text,
    Textarea,
    Divider,
    Box,
} from '@chakra-ui/react';
import React, { useCallback, useEffect, useState } from 'react';
import AddToGallery from './AddToGallery';
import { connect, useDispatch, useSelector } from "react-redux";
import { addEventsRequest } from 'redux/actions';
import { useDropzone } from 'react-dropzone';
import uploadImage from 'assets/img/upload.png'
import moment from 'moment';
import { updateEventsRequest } from 'redux/actions';
import { BASE_URL_CLOUD } from 'constants';
import toast from 'react-hot-toast';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { deleteImageGalleryRequest } from 'redux/actions';

const animatedComponents = makeAnimated();

const AddEventForm = ({ isOpen, onClose, eventData }) => {
    const [selectedPacks, setSelectedPacks] = useState([]);
    const [name, setName] = useState('');
    const [date, setDate] = useState('');
    const [place, setPlace] = useState('');
    const [time, setTime] = useState('');
    const [description, setDescription] = useState('');
    const [gallery, setGallery] = useState([]);

    const [preview, setPreview] = useState(null);
    const [previewFile, setPreviewFile] = useState(null);

    const loading = useSelector((state) => state.DashboardReducer.loading_action);
    const closeModal = useSelector((state) => state.DashboardReducer.closeModal);
    const products = useSelector((state) => state.DashboardReducer.products);

    const packs = products?.filter((ele) => ele.type === 'event')?.map((ele) => {
        return {
            value: ele._id,
            label: ele.name + ` ${ele.price}`
        }
    });

    useEffect(() => {
        if (closeModal) {
            onClose();
        }
    }, [closeModal]);

    useEffect(() => {
        // Mettez à jour les champs du formulaire lorsque eventData change
        if (eventData) {
            setName(eventData.name || '');
            setDate(moment(eventData.date).format('YYYY-MM-DD') || '');
            setPlace(eventData.place || '');
            setTime(moment(eventData.time, 'HH:mm').format('HH:mm') || '');;
            setDescription(eventData.description || '');
            setGallery(addPathToImageGalleries(eventData?.gallery || []) || []);
            const formattedPack = eventData.pack.map(p => ({ value: p._id, label: p.name + ` ${p.price}` }));
            setSelectedPacks(formattedPack);
            if (eventData.previewImage) {
                setPreviewFile(BASE_URL_CLOUD + eventData.previewImage);
            }
        }
    }, [eventData]);


    const dispatch = useDispatch();

    const onDrop = useCallback((acceptedFiles) => {
        const file = acceptedFiles[0];

        const reader = new FileReader();

        reader.onload = function () {
            setPreviewFile(reader.result);

        }

        reader.readAsDataURL(file);

        setPreview(file);
    }, []);

    const { previewImage, getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop
    });

    let handleNameChange = (e) => {
        let inputValue = e.target.value
        setName(inputValue)
    }

    let handleDateChange = (e) => {
        let inputValue = e.target.value
        setDate(inputValue)
    }

    let handleTimeChange = (e) => {
        let inputValue = e.target.value
        setTime(inputValue)
    }

    let handlePlaceChange = (e) => {
        let inputValue = e.target.value
        setPlace(inputValue)
    }

    let handleDescriptionChange = (e) => {
        let inputValue = e.target.value
        setDescription(inputValue)
    }

    const [addFiles, setAddFiles] = useState([]);
    const idEvent = eventData ? eventData._id : null;

    const handleAddImages = (images) => {
        setGallery(gal => [...gal, ...images]);
        // setAddFiles(val => [...val, ...images])
    };

    const handleDeleteImage = (imageUrl) => {
        // Vérifiez que l'URL de l'image est définie et est une chaîne de caractères
        if (imageUrl && typeof imageUrl === 'string' && imageUrl.startsWith(BASE_URL_CLOUD)) {
            // Retirez la partie spécifique de l'URL liée à Cloudinary
            const relativePath = imageUrl.replace(BASE_URL_CLOUD, '');

            // Dispatch pour supprimer l'image
            dispatch(deleteImageGalleryRequest({ eventId: eventData?._id, imageUrl: relativePath }));
        }

        // Met à jour la galerie locale
        setGallery((prevGallery) => prevGallery.filter((image) => image !== imageUrl));
        // Vous devrez également peut-être ajuster la mise à jour de addFiles si nécessaire
        setAddFiles((val) => val.filter((image) => image !== imageUrl));
    };

    const handlePackChange = (selectedOptions) => {
        setSelectedPacks(selectedOptions);
    };

    const packValues = selectedPacks.map((pack) => pack.value);

    const handleAddEvent = async (e) => {
        e.preventDefault();

        if (!name || !date || !place || !time || !selectedPacks || !preview) {
            toast.error("Verifiez que vous avez rempli tout les champs obligatoires")
            return;
        }

        try {
            const eventData = new FormData();
            eventData.append('name', name);
            eventData.append('date', date);
            eventData.append('place', place);
            eventData.append('time', time);
            eventData.append('pack', JSON.stringify(packValues));
            eventData.append('description', description);
            eventData.append('preview', preview);
            gallery?.forEach((image, index) => {
                eventData.append(`gallery[]`, image);
            });

            // Appel de l'action pour l'ajout
            dispatch(addEventsRequest(eventData));
            setName('');
            setDate('');
            setPlace('');
            setTime('');
            setDescription('');
            setGallery([]);
            setPreview(null)

        } catch (error) {
            console.error(error);
        }

    }


    const handleUpdateEvent = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (!name || !date || !place || !time || !selectedPacks) {
            toast.error("Verifiez que vous avez rempli tout les champs obligatoires")
            return;
        }

        try {
            const eventData = new FormData();
            eventData.append('name', name);
            eventData.append('date', date);
            eventData.append('place', place);
            eventData.append('time', time);
            eventData.append('pack', JSON.stringify(packValues));
            eventData.append('description', description);
            if (preview) {
                eventData.append('preview', preview);
            }
            gallery?.forEach((image, index) => {
                eventData.append(`gallery`, image);
            });


            dispatch(updateEventsRequest(idEvent, eventData));
        } catch (error) {
            console.error(error);
        }
    }

    const addPathToImageGalleries = (arr = []) => {
        return arr.map((ele) => {
            return BASE_URL_CLOUD + ele;
        })
    }

    return (
        <Modal
            closeOnOverlayClick={false}
            isOpen={isOpen}
            onClose={onClose}
            size='5xl'
            scrollBehavior={'inside'}
            isCentered
        >
            <ModalOverlay
                bg="blackAlpha.300"
                backdropFilter="blur(10px) hue-rotate(90deg)"
            />
            <ModalContent>
                <ModalBody>
                    <Flex justify="flex-start" p={4}>
                        <Text fontSize={{ base: "12px", lg: '24px' }} fontWeight="semibold">
                            {eventData?._id ? 'Modifier un évènement' : 'Ajouter un évènement'}
                        </Text>
                    </Flex>
                    <Flex direction='column' justify="flex-start" gap={4} p={4}>

                        <Flex gap={8} mb={8}>
                            <FormControl isRequired>
                                <FormLabel>Nom</FormLabel>
                                <Input
                                    value={name}
                                    onChange={handleNameChange}
                                    variant='filled' placeholder='Event name' />
                            </FormControl>
                            <FormControl isRequired>
                                <FormLabel>Date</FormLabel>
                                <Input
                                    value={date}
                                    onChange={handleDateChange}
                                    placeholder="Event Date"
                                    size="md"
                                    type="date"
                                />
                            </FormControl>
                            <FormControl isRequired>
                                <FormLabel>Heure</FormLabel>
                                <Input
                                    value={time}
                                    onChange={handleTimeChange}
                                    variant='filled' placeholder='Event hours' type="time" />
                            </FormControl>
                            <FormControl isRequired>
                                <FormLabel>Lieu</FormLabel>
                                <Input
                                    value={place}
                                    onChange={handlePlaceChange}
                                    variant='filled' placeholder='Event Location' />
                            </FormControl>
                        </Flex>
                        <Flex gap={8}>
                            <FormControl isRequired>
                                <FormLabel>Description</FormLabel>
                                <Textarea
                                    value={description}
                                    onChange={handleDescriptionChange}
                                    placeholder='Event description'
                                    size='sm'
                                    variant='filled'
                                />
                            </FormControl>
                            <FormControl isRequired>
                                <FormLabel>Niveau de billet</FormLabel>
                                <Select
                                    variant='filled'
                                    placeholder='Sélectionnez les packs'
                                    value={selectedPacks}
                                    isMulti
                                    onChange={handlePackChange}
                                    options={packs}
                                    components={animatedComponents}
                                />
                            </FormControl>
                            <FormControl isRequired>
                                <FormLabel>Image de prévisualisation</FormLabel>

                                <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    {previewFile && previewFile !== 'null' && !isDragActive ? (
                                        <Box style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: "20px" }}>
                                            <LazyLoadImage
                                                alt="Upload preview"
                                                effect="blur"
                                                src={previewFile}
                                            />
                                        </Box>
                                    ) : (
                                        <img src={uploadImage} alt="DropzoneImage" style={{ width: '100px', height: '100px', objectFit: 'contain' }} />
                                    )}
                                </div>
                            </FormControl>

                        </Flex>
                        <Flex align='center' gap={2}>
                            <Text>
                                Galerie
                            </Text>
                            <Divider />
                        </Flex>
                        <AddToGallery images={gallery}
                            onAddImages={handleAddImages}
                            onDeleteImage={handleDeleteImage}
                            eventId={eventData?._id} />
                    </Flex>
                </ModalBody>

                <ModalFooter>
                    <Flex gap={4}>
                        <Button onClick={() => onClose()} colorScheme='red' variant='solid' >
                            Annuler
                        </Button>
                        <Button onClick={eventData?._id ? handleUpdateEvent : handleAddEvent} colorScheme='green' variant='solid' isLoading={loading} loadingText='En cours...'>
                            {eventData?._id ? 'Modifier' : 'Ajouter'}
                        </Button>
                    </Flex>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default AddEventForm;
