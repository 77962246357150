import React, { useState } from "react";
// Chakra imports
import {
  Box,
  Button,
  Flex,
  IconButton,
  Image,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
// Assets
import { FaEthereum } from "react-icons/fa";
import AddEventForm from "./AddEventForm";
import { BASE_URL_CLOUD } from "constants";
import DeleteEvent from "./DeleteEvent";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { EditIcon } from "@chakra-ui/icons";

export default function EvenementsItem(props) {
  const [isHovered, setIsHovered] = useState(false);
  const [isFormOpen, setIsFormOpen] = useState(false); // Nouvel état

  const {
    image,
    name,
    date,
    lieu,
    heure,
    status,
    statusClass,
    onClick,
    event,
  } = props;

  const handleUpdateClick = () => {
    setIsFormOpen(true);
  };

  // Chakra Color Mode
  const textColor = useColorModeValue("brands.900", "white");
  const bgItem = useColorModeValue(
    { bg: "white", boxShadow: "0px 40px 58px -20px rgba(112, 144, 176, 0.12)" },
    { bg: "navy.700", boxShadow: "unset" }
  );
  return (
    <Card
      _hover={bgItem}
      bg="transparent"
      boxShadow="unset"
      px="24px"
      py="21px"
      transition="0.2s linear"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Flex
        onClick={onClick}
        cursor="pointer"
        zIndex={10}
        direction={{ base: "column", md: "row" }}
        justify="space-between"
      >
        <Flex align="center" w={{ base: "100%", md: "50%" }}>
          <Image
            alt={name}
            effect="blur"
            src={BASE_URL_CLOUD + image}
            w="66px" h="66px" objectFit="cover" borderRadius="10px" me="16px"
          />
          <Flex
            direction="column"
            w={{ base: "70%", md: "100%" }}
            me={{ base: "4px", md: "32px", xl: "10px", "3xl": "32px" }}
          >
            <Text
              color={textColor}
              fontSize={{
                base: "md",
              }}
              mb="5px"
              fontWeight="bold"
              me="14px"

            >
              {name}
            </Text>
          </Flex>
        </Flex>
        <Flex
          direction="column"
          justify="space-between"
          w={{ base: "70%", md: "25%" }}
        >
          <Text fontWeight="700" fontSize="md" color={textColor}>
            {date} à {heure}
          </Text>
          <Text fontWeight="700" fontSize="md" color={textColor}>
            {lieu}
          </Text>
          <Text fontWeight="700" fontSize="md" color={`${statusClass}`}>
            {status}
          </Text>
        </Flex>
        <Flex direction="row" align='center' justify='flex-end' zIndex={100} opacity={isHovered ? 1 : 0} transition="opacity 1s ease-out" >
          {isHovered && (
            <>
              <Flex align="center" gap={4} position='absolute'>
                <IconButton
                  isRound={true}
                  variant='outline'
                  colorScheme='green'
                  aria-label='Done'
                  fontSize='15px'
                  icon={<EditIcon />}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleUpdateClick()
                  }}
                />
                <>
                  <DeleteEvent event={event?._id} />

                  <AddEventForm
                    isOpen={isFormOpen}
                    onClose={() => setIsFormOpen(false)}
                    eventData={event}
                  />
                </>
              </Flex>

            </>
          )}
        </Flex>
      </Flex>
    </Card>
  );
}
