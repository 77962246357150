import React, { useEffect, useState } from "react";

// Chakra imports
import { Box, Button, Flex } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import { connect, useDispatch } from "react-redux";

// Assets
import UserOverview from "./components/UsersView";
import { fetchUsersRequest } from "redux/actions";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";

const Users = ({ users, loading, totalUsers }) => {
  
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const limit = 10;
  const maxPage = Math.ceil(users.length);

  useEffect(() => {
    dispatch(fetchUsersRequest({ page, limit }));
  }, [dispatch, page, limit]);

  const handlePageClick = (newPage) => {
    if (newPage >= 1 && newPage <= maxPage) {
      setPage(newPage);
    }
  };

  return (
    <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
      {/* Main Fields */}

      <Flex
        flexDirection="column"
        gridArea={{ xl: "1 / 3 / 2 / 4", "2xl": "1 / 2 / 2 / 3" }}
      >
        <Card px="0px" mb="20px">
          <UserOverview users={users} loading={loading} totalUsers={totalUsers} />
          <Box mt="4">
            <Button
              leftIcon={<ChevronLeftIcon />}
              isDisabled={page === 1}
              onClick={() => handlePageClick(page - 1)}
            >
              Préc
            </Button>
            <Button
              ml="2"
              isDisabled={page === maxPage || maxPage < limit}
              onClick={() => handlePageClick(page + 1)}
            >
              Suiv
              <ChevronRightIcon />
            </Button>
          </Box>
        </Card>
      </Flex>
      {/* Delete Product */}
    </Box>
  );
};

const mapStateToProps = ({ DashboardReducer }) => ({
  users: DashboardReducer.users,
  loading: DashboardReducer.loading,
  error: DashboardReducer.error,
  totalUsers: DashboardReducer.totalUsers
});

export default connect(mapStateToProps)(Users);
