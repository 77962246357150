import React, { useEffect, useState } from "react";
// Chakra imports
import {
  Box,
  Button,
  Flex,
  Text,
  useColorModeValue,
  WrapItem,
  useDisclosure,
  Image,
  IconButton,
} from "@chakra-ui/react";
import { MdAddCircle } from "react-icons/md";

// Custom components
import EvenementItem from "views/admin/evenements/components/EvenementItem";
import Card from "components/card/Card.js";

import { connect, useDispatch } from "react-redux";
import Loading from "components/loading";
import AddEventForm from "./components/AddEventForm";
import EvenementDetails from "./components/EvenementDetails";
import { fetchEventsRequest } from "redux/actions";
import moment from "moment";
import users from "../users";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";

require("moment/locale/fr");

const currentDate = moment();

function calculateEventStatus(eventDate) {
  const eventMoment = moment(eventDate);

  if (currentDate.isBefore(eventMoment, "day")) {
    return "À venir";
  } else if (currentDate.isSame(eventMoment, "day")) {
    return "En cours";
  } else {
    return "Passé";
  }
}

function calculateEventStatusClass(eventDate) {
  const eventMoment = moment(eventDate);

  if (currentDate.isBefore(eventMoment, "day")) {
    return "green"; // Par exemple, "À venir" peut être en vert
  } else if (currentDate.isSame(eventMoment, "day")) {
    return "blue"; // Par exemple, "En cours" peut être en bleu
  } else {
    return "red"; // Par exemple, "Passé" peut être en rouge
  }
}

const Evements = ({ events, loading }) => {
  const dispatch = useDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);

  const [page, setPage] = useState(1);
  const limit = 10;
  const maxPage = Math.ceil(events.length);

  const openModal = (event) => {
    setSelectedEvent(event);
    setIsEditing(true);
  };

  const closeModal = () => {
    setIsEditing(false);
    setSelectedEvent(null);
  };

  useEffect(() => {
    // dispatch(fetchProductsRequest());
    dispatch(fetchEventsRequest({ page, limit }));
  }, [dispatch, page, limit]);

  const handlePageClick = (newPage) => {
    if (newPage >= 1 && newPage <= maxPage) {
      setPage(newPage);
    }
  };

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleItemClick = (index) => {
    setSelectedRowIndex(index);
    setIsModalOpen(true);
  };

  const dataColor = useColorModeValue("navy.700", "white");
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
      {/* Main Fields */}
      <Card p="0px">
        <Flex
          align={{ sm: "flex-start", lg: "center" }}
          justify="space-between"
          w="100%"
          px="22px"
          py="18px"
        >
          <Text color={textColor} fontSize="xl" fontWeight="600">
            Tout les évènements
          </Text>
          <WrapItem>
            <Button
              rightIcon={<MdAddCircle />}
              colorScheme="whatsapp"
              onClick={() => {
                onOpen();
              }}
            >
              Ajouter
            </Button>
            <AddEventForm isOpen={isOpen} onClose={onClose} />
          </WrapItem>
        </Flex>
        {loading ? (
          <>
            <Loading />
          </>
        ) : (
          <>
            {events.length === 0 ? (
              <Flex
                direction="column"
                justifyContent="center"
                align="center"
                height={{ sm: "10rem", lg: "30rem" }}
              >
                <Image
                  src="https://bibemella.isomora.com/wp-content/uploads/2023/10/logo-fbe.png"
                  width={50}
                  height={50}
                  mb={4}
                />
                <Text color={dataColor} fontSize="sm">
                  Aucun évènement disponible
                </Text>
              </Flex>
            ) : (
              <>
                {events?.map((event, index) => (
                  <EvenementItem
                    key={index}
                    name={event.name}
                    image={event.previewImage}
                    date={moment(event.date).format("D MMMM YYYY")}
                    lieu={event.place}
                    heure={moment(event.time, "hh:mm")
                      .locale("fr")
                      .format("hh:mm")}
                    status={calculateEventStatus(event.date)}
                    statusClass={calculateEventStatusClass(event.date)}
                    onClick={() => handleItemClick(index)}
                    isHovered={true}
                    setIsFormOpen={openModal}
                    event={event}
                  />
                ))}
                <Box mt="4">
                  <Button
                    leftIcon={<ChevronLeftIcon />}
                    isDisabled={page === 1}
                    onClick={() => handlePageClick(page - 1)}
                  >
                    Préc
                  </Button>
                  <Button
                    ml="2"
                    isDisabled={page === maxPage || maxPage < limit}
                    onClick={() => handlePageClick(page + 1)}
                  >
                    Suiv
                    <ChevronRightIcon />
                  </Button>
                </Box>

                <EvenementDetails
                  isOpen={isModalOpen}
                  onClose={() => setIsModalOpen(false)}
                  selectedData={
                    selectedRowIndex !== null ? events[selectedRowIndex] : null
                  }
                  eventId={selectedEvent ? selectedEvent?._id : null}
                />
                {isEditing && (
                  <AddEventForm
                    isOpen={isEditing}
                    onClose={closeModal}
                    eventData={selectedEvent}
                    eventId={selectedEvent?._id}
                    isEditing={isEditing}
                  />
                )}
              </>
            )}
          </>
        )}
      </Card>
    </Box>
  );
};

const mapStateToProps = ({ DashboardReducer }) => ({
  events: DashboardReducer.events,
  loading: DashboardReducer.loading,
  error: DashboardReducer.error,
});

export default connect(mapStateToProps)(Evements);
